const itemsForm = document.querySelector(
  "#alloy-collection-creation-items-form"
);
const selectedItemsEl = document.querySelector("#alloy-selected-items");
const selectedItems = new Set();

const fieldId = 8; // 'Items' field ID
const gravityFormWrapper = document.querySelector(`#collection-creator-form`);
const gravityForm = gravityFormWrapper
  ? gravityFormWrapper.querySelector("form")
  : null;
const itemsField = document.querySelector(`input[name='input_${fieldId}']`);
const selectedItemsCount = document.querySelector(`#selected-items-count`);

const clearAllButton = document.querySelector("#alloy-clear-selected-items");

const nameFieldId = 5; // 'Name' field ID
const nameField = document.querySelector(`input[name='input_${nameFieldId}']`);
const nameLabel = document.querySelector(`#alloy-collection-creator-title`);

function onItemSelect() {
  if (!itemsForm || !selectedItemsEl) {
    return;
  }
  itemsForm.addEventListener("change", (event) => {
    const checkbox = event.target;

    if (checkbox.checked) {
      if (selectedItems.has(checkbox.value)) {
        return;
      }
      appendSelectedItem(
        checkbox.value,
        checkbox.dataset.title,
        checkbox.dataset.thumbnail
      );
      selectedItems.add(checkbox.value);
    } else {
      removeSelectedItem(checkbox.value);
      selectedItems.delete(checkbox.value);
    }
    if (selectedItems.size > 1) {
      gravityForm.querySelector("input[type='submit']").disabled = false;
    }
    selectedItemsCount.innerHTML = selectedItems.size;
  });

  function appendSelectedItem(itemId, itemTitle, itemThumbnailUrl) {
    const item = document.createElement("li");
    item.id = `selected_item_${itemId}`;
    const label = document.createElement("label");
    label.htmlFor = `library_item_${itemId}`;

    if (itemThumbnailUrl) {
      const itemThumbnail = document.createElement("img");
      itemThumbnail.src = itemThumbnailUrl;
      itemThumbnail.alt = itemTitle;
      label.appendChild(itemThumbnail);
    }

    const itemName = document.createElement("span");
    itemName.textContent = itemTitle;
    label.appendChild(itemName);

    const button = document.createElement("span");
    button.classList.add("button");
    button.textContent = "✕";
    label.appendChild(button);

    item.appendChild(label);

    selectedItemsEl.append(item);
  }

  function removeSelectedItem(itemId) {
    selectedItemsEl.removeChild(
      selectedItemsEl.querySelector(`#selected_item_${itemId}`)
    );
  }
}

function onClearClick() {
  if (!clearAllButton) {
    return;
  }
  clearAllButton.addEventListener("click", () => {
    resetForm();
  });
}

function onNameFieldChange() {
  if (!nameField || !nameLabel) {
    return;
  }
  nameField.addEventListener("input", (e) => {
    const name = e.target.value;
    nameLabel.textContent = name;
  });
}

function resetForm() {
  selectedItems.clear();
  selectedItemsCount.innerHTML = 0;
  itemsForm.reset();
  selectedItemsEl.innerHTML = "";
}

function updateItemsFieldOnSubmit() {
  if (!itemsField) {
    return;
  }
  gravityForm.addEventListener("submit", () => {
    const libraryItems = Array.from(selectedItems);
    itemsField.value = libraryItems.join(", ");

    resetForm();

    return true;
  });
}

function deleteCollection() {
  const deleteButton = document.getElementById(
    "alloy-show-delete-collection-dialog"
  );
  const deleteDialog = document.getElementById(
    "alloy-delete-collection-dialog"
  );
  const closeDialogButton = document.getElementById(
    "alloy-close-delete-collection-dialog"
  );
  const confirmDeleteButton = document.getElementById(
    "alloy-confirm-collection-deletion"
  );

  if (deleteButton) {
    deleteButton.addEventListener("click", () => {
      deleteDialog.showModal();
    });

    closeDialogButton.addEventListener("click", () => {
      deleteDialog.close();
    });
  }
}

// Observe when items are filtered and restore form state (selected items)
function observeFormFieldChanges() {
  if (!itemsForm) return;

  const observer = new MutationObserver(() => {
    selectedItems.forEach((itemId) => {
      const item = document.querySelector(
        "input[type='checkbox'][value='" + itemId + "']"
      );
      if (item) {
        item.checked = true;
      }
    });
  });

  observer.observe(itemsForm, {
    childList: true, // Observe direct children
    subtree: true, // Observe all descendants
  });
}

export default () => {
  deleteCollection();

  if (gravityForm) {
    gravityForm.querySelector("input[type='submit']").disabled = true;
    onItemSelect();
    onClearClick();
    onNameFieldChange();
    updateItemsFieldOnSubmit();
    observeFormFieldChanges();
  }
};
