import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.defaults({
    toggleActions: "restart complete restart pause",
    markers:
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? true
        : false,
  });
  // END Setup 🧦 GSAP -------------------------------------//
  // //--------------------------------//
  // // Slide navigation
  // //--------------------------------//
  // const navigationSlideToggle = document.querySelector(
  //   "#navigation-slide-toggle",
  // );
  // const body = document.querySelector("body");
  // if (navigationSlideToggle) {
  //   navigationSlideToggle.addEventListener("click", () => {
  //     body.classList.toggle("showNavigationSlide");
  //     navigationSlideToggle.setAttribute(
  //       "aria-expanded",
  //       navigationSlideToggle.getAttribute("aria-expanded") == "true"
  //         ? "false"
  //         : "true",
  //     );
  //   });
  // }
  // // jam admin
  // const jamSidebar = document.querySelector("#jamSidebar");

  // if (jamSidebar) {
  //   const jamSidebarBtn = jamSidebar.querySelector("button");
  //   const timelineMenu = gsap.timeline({
  //     defaults: {
  //       duration: 0.3,
  //     },
  //   });
  //   timelineMenu.to(jamSidebar.querySelectorAll("button span:first-child"), {
  //     y: 8,
  //   });
  //   timelineMenu.to(
  //     jamSidebar.querySelectorAll("button span:last-child"),
  //     {
  //       y: -7,
  //     },
  //     "<",
  //   );
  //   timelineMenu.to(jamSidebar, {
  //     x: 0,
  //     width: "auto",
  //   });
  //   timelineMenu.to(
  //     jamSidebar.querySelectorAll("button span:nth-child(2)"),
  //     {
  //       opacity: 0,
  //     },
  //     "<",
  //   );
  //   timelineMenu.to(
  //     jamSidebar.querySelectorAll("button span:first-child"),
  //     {
  //       rotation: 45,
  //       transformOrigin: "center center",
  //     },
  //     "<",
  //   );
  //   timelineMenu.to(
  //     jamSidebar.querySelectorAll("button span:last-child"),
  //     {
  //       rotation: -45,
  //       transformOrigin: "center center",
  //     },
  //     "<",
  //   );
  //   timelineMenu.from(
  //     jamSidebar.querySelectorAll("li span"),
  //     {
  //       autoAlpha: 0,
  //       x: -20,
  //     },
  //     "-=0.2",
  //   );

  //   // Geen idee wat deze code doet maar is belangrijk
  //   timelineMenu.reversed(true);

  //   // Functie die de animatie afspeeld of reversed
  //   function animateMenu() {
  //     if (timelineMenu.reversed()) {
  //       timelineMenu.reversed(!timelineMenu.reversed()).timeScale(1); // Speel aimatie
  //     } else {
  //       timelineMenu.timeScale(3).reversed(!timelineMenu.reversed()); // Reverse animatie en speel 3x zo snel af
  //     }
  //   }

  //   // On 🐭 click add class to body and toggle 🦻 ARIA lables
  //   jamSidebarBtn.addEventListener("click", () => {
  //     // body.classList.toggle('showNavigationSlide');
  //     // navigationSlideToggle.setAttribute('aria-expanded', navigationSlideToggle.getAttribute('aria-expanded') == 'true' ? 'false' : 'true');
  //     animateMenu();
  //   });

  //   // // On 🐭  hover
  //   // jamSidebar.addEventListener("mouseenter", function () {
  //   //   timelineMenu.reversed(!timelineMenu.reversed()).timeScale(1); // Speel aimatie
  //   // });
  //   // // On 🐭  leave
  //   // jamSidebar.addEventListener("mouseleave", function () {
  //   //   timelineMenu.timeScale(3).reversed(!timelineMenu.reversed()); // Reverse animatie en speel 3x zo snel af
  //   // });
  // }
  // // END Slide navigation --------------//
  //--------------------------------//
  // Card animation
  //--------------------------------//
  function cardAnimation(container) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container, // What element triggers the scroll
        scrub: false, // Add a small delay of scrolling and animation. `true` is direct
        start: "-30% center", // Can be top, center, bottom
        end: "80% center", // Can be top, center, bottom
      },
    });
    tl.from(container.querySelectorAll("li"), {
      // duration: 0.2,
      y: 100,
      opacity: 0,
      stagger: {
        each: 0.1,
        ease: "Power4.out",
      },
    });
  }
  // ? Below all these elements have the same animation based on hte function above
  document
    .querySelectorAll(".ACF-jam_products .overview")
    .forEach((container) => {
      cardAnimation(container);
    });
  // document.querySelectorAll(".ACF-split_content").forEach((container) => {
  //   cardAnimation(container);
  // });
  // ScrollTrigger.batch(".alloy-card", {
  //           // trigger: container, // What element triggers the scroll
  //         scrub: false, // Add a small delay of scrolling and animation. `true` is direct
  //         start: "top center", // Can be top, center, bottom
  //         end: "100% center", // Can be top, center, bottom
  //   onEnter: (elements, triggers) => {
  //     gsap.from(elements, {
  //       // scrollTrigger: {

  //       // },
  //       duration: 0.2,
  //       y: 100,
  //       opacity: 0,
  //       // stagger: {
  //       // each: 0.1,
  //       // ease: "Power4.out"
  //       // }
  //       stagger: 0.15
  //     });
  //     // console.log(elements.length, "elements entered");
  //   },
  //   // onLeave: (elements, triggers) => {
  //   //   gsap.to(elements, { opacity: 0, stagger: 0.15 });
  //   //   console.log(elements.length, "elements left");
  //   // }
  // });
  // document.querySelectorAll('.ACF-include_single_item').forEach(container => {
  //   cardAnimation(container)
  // })
  // document.querySelectorAll('.ACF-include_content_items').forEach(container => {
  //   cardAnimation(container)
  // })
  // END card animation --------------//

  //--------------------------------//
  // ⏱️ Timeline
  //--------------------------------//
  document.querySelectorAll(".ACF-microport_timeline").forEach((container) => {
    const items = container.querySelectorAll('input[type="radio"]');
    const descriptions = container.querySelectorAll(
      "#timeline-descriptions-wrapper .content",
    );
    items.forEach((item) => {
      item.addEventListener("change", function () {
        // console.warn(item.dataset.description);
        descriptions.forEach((el) => {
          el.classList.remove("active");
        });
        Array.from(descriptions)
          .find((date) => date.dataset.description === item.dataset.description)
          .classList.add("active");
        // console.warn(descriptions);
        // console.warn(Array.from(descriptions).find(date => date.dataset.description === item.dataset.description));
      });
    });
  });
  // END ⏱️ Timeline --------------//

  //--------------------------------//
  // Form toggle
  //--------------------------------//
  document.querySelectorAll(".form-toggle-container").forEach((container) => {
    const toggle = container.querySelector(".form-toggle");
    // The animation
    const tl = gsap.timeline({
      defaults: { duration: 0.3 },
      reversed: true,
      paused: true,
    });
    tl.set(container.querySelector(".hide"), { autoAlpha: 1 });

    tl.to(container.querySelector(".hide"), { height: "auto" });
    tl.to(container.querySelector(".icon"), { rotate: 180 }, "<");

    // The event listener
    toggle.addEventListener("click", (e) => {
      tl.reversed() ? tl.play() : tl.reverse();
    });
  });
  // END Form toggle  --------------//
};
