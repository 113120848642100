export default () => {
  (function ($) {
    // jQuery code here...

    //------------------------------------------------------//
    // Meded Faculty hide empty slots
    //------------------------------------------------------//
    if ($(".pending-faculty-container li").length == 0) {
      $("h6.pending, .pending-faculty-container").remove();
    }
    if ($(".confirmed-faculty-container li").length == 0) {
      $("h6.confirmed, .confirmed-faculty-container").remove();
    }
    if ($(".invited-faculty-container li").length == 0) {
      $("h6.invited, .invited-faculty-container").remove();
    }

    //------------------------------------------------------//
    // END Meded Faculty hide empty slots
    //------------------------------------------------------//

    /* Leave website link alert --------------*/
    function leave_now(event) {
      var choice =
        "​​You are now leaving MicroPort's website. These related links are provided as a service to you; however, MicroPort makes no endorsements, warranties, or representations, express or implied, regarding content on these websites. You are responsible for making an independent determination of the qualifications of their content and whether it suits your particular needs.";

      return window.alert(choice);
    }

    $("a").on("click", function (e) {
      var internelinks =
        this.href.lastIndexOf("#") >= 0 || this.href.indexOf("javascript") >= 0;
      if (!internelinks) {
        if (
          this.href.startsWith(window.location.origin) ||
          this.href.startsWith("https://s3-eu-central-1.amazonaws.com/wp-jam-eu") ||
          this.href.startsWith("https://wp-jam-us.s3.eu-central-1.amazonaws.com/") ||
          this.href.startsWith("https://s3-eu-central-1.amazonaws.com/wp-jam-us") ||
          this.href.startsWith("http://s3.eu-central-1.amazonaws.com/wp-jam-eu") ||
          this.href.startsWith("https://wp-jam-eu.s3.eu-central-1.amazonaws.com") ||
          this.href.startsWith("http://wp-jam-eu.s3.eu-central-1.amazonaws.com") ||
          this.href.startsWith("https://jam-us2.alloy.studio") ||
          this.href.startsWith("https://wp-us-jam-us.s3.us-east-2.amazonaws.com") ||
          this.href.startsWith("https://jointacademymicroport.com") ||
          this.href.startsWith("http://jointacademymicroport.com") ||
          this.href.startsWith("https://www.jointacademymicroport.com") ||
          this.href.startsWith("http://www.jointacademymicroport.com") ||
          this.href.startsWith("https://microportjam.com/") ||
          this.href.startsWith("http://microportjam.com/") ||
          this.href.startsWith("https://www.microportjam.com/") ||
          this.href.startsWith("http://www.microportjam.com/") ||
          this.href.startsWith("https://microport-jam.com/") ||
          this.href.startsWith("http://microport-jam.com/") ||
          this.href.startsWith("https://www.microport-jam.com/") ||
          this.href.startsWith("http://www.microport-jam.com/")
        ) {
          //do nothing
        } else {
          leave_now();
        }
      }
    });
    /* END Leave website link alert --------------*/

    $("#mobile-filter-toggle").on("click", function () {
      $(this).toggleClass("opened");
      $("form.searchandfilter").slideToggle();
    });

    // Pushes cta's to the bottom of the results container on small screens.
    if (($(window).width() <= 920)) {
      $(".sov-cta").appendTo(".overview.overview-events");
      $(".filter-container .extra").appendTo(".results-container");
    }
  })(jQuery); // Fully reference jQuery after this point.
};
